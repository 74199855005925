import { SET_NAV_VISIBLE, SET_TRIGGER_VISIBLE } from '../actions/types';

const initialState = {
  triggerIsVisible: false,
  navIsVisible: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_TRIGGER_VISIBLE:
      return {
        ...state,
        triggerIsVisible: action.payload,
      };
    case SET_NAV_VISIBLE:
      return {
        ...state,
        navIsVisible: action.payload,
      };
    default:
      return state;
  }
}
